import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NoteApp.css';

function NoteApp() {
    // Состояния для списка заметок, заголовка и содержания
    const [notes, setNotes] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    // Хук эффекта для загрузки заметок при первом рендере компонента
    useEffect(() => {
        fetchNotes(); // Загрузка заметок из API
    }, []);

    // Функция для получения всех заметок с сервера
    const fetchNotes = async () => {
        try {
            const response = await axios.get('/api/notes'); // Запрос на получение всех заметок
            setNotes(response.data.reverse()); // Сохранение заметок в состояние
        } catch (error) {
            console.error("Ошибка при загрузке заметок", error);
        }
    };

    // Функция для создания новой заметки
    const createNote = async () => {
        try {
            await axios.post('/api/notes', { title, content }); // Отправляем POST-запрос для создания заметки
            fetchNotes(); // Обновляем список заметок после создания
            setTitle(''); // Очищаем поле заголовка
            setContent(''); // Очищаем поле содержания
        } catch (error) {
            console.error("Ошибка при создании заметки", error);
        }
    };

    // Функция для удаления заметки
    const deleteNote = async (id) => {
        try {
            await axios.delete(`/api/notes/${id}`); // Отправляем DELETE-запрос для удаления заметки по id
            fetchNotes(); // Обновляем список заметок после удаления
        } catch (error) {
            console.error("Ошибка при удалении заметки", error);
        }
    };

    return (
        <div className="note-app">
            <h1>Заметки</h1>
            <div className="note-form">
                {/* Поле ввода заголовка заметки */}
                <input
                    type="text"
                    className="note-input"
                    placeholder="Заголовок"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)} // Обновляем состояние заголовка при изменении ввода
                />
                {/* Поле ввода содержания заметки */}
                <textarea
                    className="note-textarea"
                    placeholder="Содержание"
                    value={content}
                    onChange={(e) => setContent(e.target.value)} // Обновляем состояние содержания при изменении ввода
                ></textarea>
                {/* Кнопка для создания новой заметки */}
                <button className="note-button" onClick={createNote}>Создать заметку</button>
            </div>
            <div className="note-list">
                {/* Отображение списка заметок */}
                {notes.map((note) => (
                    <div key={note.id} className="note-item">
                        <h2 className="note-title">{note.title}</h2>
                        <p className="note-content">{note.content}</p>
                        {/* Кнопка для удаления заметки */}
                        <button className="note-delete-button" onClick={() => deleteNote(note.id)}>Удалить</button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NoteApp;